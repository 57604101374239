<template>
  <aside class="tournament-leaderboard" :class="{ modal: isModal }">
    <div v-if="!isModal" class="tournament-leaderboard__header">
      {{ getContent(props.currentLocaleCommonContent, props.defaultLocaleCommonContent, 'leaderboard.title') }}
    </div>
    <template v-if="profile">
      <div class="tournament-leaderboard__tb-container">
        <div class="tournament-leaderboard__tb">
          <div class="tournament-leaderboard__tb-head">
            <div class="tournament-leaderboard__tb-th first-cell">
              <div class="first-cell__position">
                {{
                  getContent(
                    props.currentLocaleCommonContent,
                    props.defaultLocaleCommonContent,
                    'leaderboard.table.position'
                  )
                }}
              </div>
              <div class="first-cell__name">
                {{
                  getContent(
                    props.currentLocaleCommonContent,
                    props.defaultLocaleCommonContent,
                    'leaderboard.table.yourPosition'
                  )
                }}
              </div>
            </div>

            <div class="tournament-leaderboard__tb-th">
              {{
                getContent(
                  props.currentLocaleCommonContent,
                  props.defaultLocaleCommonContent,
                  'leaderboard.table.points'
                )
              }}
            </div>

            <div class="tournament-leaderboard__tb-th">
              {{
                getContent(
                  props.currentLocaleCommonContent,
                  props.defaultLocaleCommonContent,
                  'leaderboard.table.prize'
                )
              }}
            </div>
          </div>

          <div class="tournament-leaderboard__tb-body">
            <div class="tournament-leaderboard__tb-row your-position">
              <div class="tournament-leaderboard__tb-td first-cell">
                <div class="first-cell__position" :class="{ 'is-empty': !currentPlayer?.place }">
                  {{ currentPlayer?.place || '—' }}
                </div>
                <div class="first-cell__name">
                  {{
                    profile?.nickname
                      ? maskName(profile?.nickname)
                      : getContent(
                          props.currentLocaleCommonContent,
                          props.defaultLocaleCommonContent,
                          'leaderboard.defaultNickname'
                        )
                  }}
                </div>
              </div>
              <div class="tournament-leaderboard__tb-td" :class="{ 'is-empty': !currentPlayer?.points }">
                {{ currentPlayer?.points || '—' }}
              </div>
              <div class="tournament-leaderboard__tb-td">
                <template v-if="props.tournamentDefiniteData.prizes?.[(currentPlayer?.place ?? 0) - 1]?.title">
                  {{ props.tournamentDefiniteData.prizes[(currentPlayer?.place ?? 0) - 1].title }}
                </template>

                <span v-else class="is-empty">—</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <atomic-divider />
    </template>

    <div class="tournament-leaderboard__tb-container">
      <div class="tournament-leaderboard__tb">
        <div class="tournament-leaderboard__tb-head">
          <div class="tournament-leaderboard__tb-th first-cell">
            <div class="first-cell__position">
              {{
                getContent(
                  props.currentLocaleCommonContent,
                  props.defaultLocaleCommonContent,
                  'leaderboard.table.position'
                )
              }}
            </div>
            <div class="first-cell__name">
              {{
                getContent(
                  props.currentLocaleCommonContent,
                  props.defaultLocaleCommonContent,
                  'leaderboard.table.player'
                )
              }}
            </div>
          </div>

          <div class="tournament-leaderboard__tb-th">
            {{
              getContent(props.currentLocaleCommonContent, props.defaultLocaleCommonContent, 'leaderboard.table.points')
            }}
          </div>

          <div class="tournament-leaderboard__tb-th">
            {{
              getContent(props.currentLocaleCommonContent, props.defaultLocaleCommonContent, 'leaderboard.table.prize')
            }}
          </div>
        </div>

        <div class="tournament-leaderboard__tb-body">
          <div
            v-for="leader in leaders"
            :key="leader.playerId"
            class="tournament-leaderboard__tb-row"
            :class="{ 'is-accented': leader.playerId === profile?.id }"
          >
            <div class="tournament-leaderboard__tb-td first-cell">
              <div class="first-cell__position">
                {{ leader.place }}
              </div>
              <div class="first-cell__name">
                {{ leader.nickname ? maskName(leader.nickname) : 'unknown' }}
              </div>
            </div>
            <div class="tournament-leaderboard__tb-td">{{ leader.points }}</div>
            <div class="tournament-leaderboard__tb-td">
              <template v-if="props.tournamentDefiniteData.prizes?.[(leader.place ?? 0) - 1]?.title">
                {{ props.tournamentDefiniteData.prizes[leader.place - 1].title }}
              </template>

              <span v-else class="is-empty">—</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!isModal && countToShow < (tournamentDefiniteData?.leaderboard?.length || 0)"
      class="tournament-leaderboard__show-more"
      @click="emit('showMore')"
    >
      {{ getContent(props.currentLocaleCommonContent, props.defaultLocaleCommonContent, 'leaderboard.showMore') }}
    </div>
  </aside>
</template>

<script setup lang="ts">
  import type { CITournamentCommon, ITournament } from '~/types';
  import { storeToRefs } from 'pinia';

  const emit = defineEmits(['showMore']);
  const props = defineProps<{
    currentLocaleCommonContent: Maybe<CITournamentCommon>;
    defaultLocaleCommonContent: Maybe<CITournamentCommon>;
    tournamentDefiniteData: ITournament;
    isModal?: boolean;
  }>();

  const { getContent } = useProjectMethods();
  const globalStore = useGlobalStore();
  const { isMobile } = storeToRefs(globalStore);
  const profileStore = useProfileStore();
  const { profile } = storeToRefs(profileStore);
  function maskName(name: string, visibleChars: number = 4, maskChar: string = '*'): string {
    if (!name) return '';
    return name.substring(0, visibleChars) + maskChar.repeat(5);
  }
  const currentPlayer = computed(() => props.tournamentDefiniteData.playerEntry);
  const countToShow = computed(() => (isMobile.value ? 5 : 10));
  const leaders = computed(() => {
    const leadersList = props.tournamentDefiniteData.leaderboard || [];
    return props.isModal ? leadersList : leadersList.slice(0, countToShow.value);
  });
</script>
<style src="~/assets/styles/components/tournament/leaderboard.scss" lang="scss" />
